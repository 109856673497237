import moment from "moment";

export function formatTime(totalMinutes: number) {
  const duration = moment.duration(totalMinutes, "minutes");
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}:${String(seconds).padStart(2, "0")}`;
}
